import { useQuery } from 'react-query';
import { StringLiteralLike } from 'typescript';

import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';

import { VVNotification } from '../types';

type GetVVNotificationsResponse = {
  notifications: VVNotification[];
  error?: string;
  success?: string;
};

export type VVNotificationsData = VVNotification[];

export const getVVNotifications = (clinic_user_id: number): Promise<VVNotificationsData> => {
  return axios
    .post('/vue_api/get_notifications', { clinic_user_id: clinic_user_id })
    .then((resp: GetVVNotificationsResponse | any) => {
      const data: VVNotificationsData = resp.notifications;
      return data;
    });
};

type UseVVNotificationsOptions = {
  clinic_user_id: number;
  config?: QueryConfig<typeof getVVNotifications>;
};

export const useVVNotifications = ({ config, clinic_user_id }: UseVVNotificationsOptions) => {
  return useQuery({
    ...config,
    cacheTime: 0,
    queryKey: ['notifications'],
    queryFn: () => getVVNotifications(clinic_user_id),
  });
};
