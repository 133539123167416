import {
  Box,
  Icon,
  Badge,
  Text,
  MenuButton,
  Menu,
  Flex,
  MenuList,
  MenuItem,
  HStack,
} from '@chakra-ui/react';
import { HomeIcon, DotsVerticalIcon } from '@heroicons/react/solid';
// import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Patient } from '@/features/patients';
import { useClinicDevices } from '@/features/patients/api/getClinicDevices';
import { HMDIcon } from '@/components/Icons/HMDIcon';

import VRHeadsetIcon from '@iconify/icons-teenyicons/vr-headset-solid';
import { Icon as IconifyIcon } from '@iconify/react/dist/offline';
// import { usePatientStore } from '@/stores/patients';

type PatientListCardProps = {
  patient: Patient;
  isSelected: boolean;
  clickHandler?: () => void;
};

export const PatientListCard = ({ patient, clickHandler, isSelected }: PatientListCardProps) => {
  const { data: clinicDevicesData, isLoading: isClinicDevicesDataLoading } = useClinicDevices({});

  const textColor = isSelected ? 'blue.500' : 'gray.900';
  const colorScheme = isSelected ? 'blue' : 'gray';
  const infoColor = isSelected ? 'gray.800' : 'gray.500';
  const boxShadow = isSelected ? 'outline' : 'none';
  const boxShadowHover = isSelected
    ? 'rgba(66, 153, 225, 1.0) 0px 0px 0px 3px'
    : 'rgb(200,200,200) 0px 0px 0px 3px';

  return (
    // <Box pos="relative">
    //   <Menu>
    //     <MenuButton pos="absolute" right={0} zIndex={101}>
    //       <Icon as={DotsVerticalIcon} />
    //     </MenuButton>
    //     <MenuList fontSize="xs" fontWeight="bold" minWidth="140px">
    //       <MenuItem onClick={() => {}}>Last Played</MenuItem>
    //       <MenuItem onClick={() => {}}>Added Date</MenuItem>
    //       <MenuItem onClick={() => {}}>Username</MenuItem>
    //       <MenuItem onClick={() => {}}>Patient ID</MenuItem>
    //       <MenuItem onClick={() => {}}>Custom ID</MenuItem>
    //     </MenuList>
    //   </Menu>
    <Box
      color={infoColor}
      borderWidth="0px"
      borderRadius="lg"
      overflow="hidden"
      backgroundColor="white"
      boxShadow={boxShadow}
      onClick={() => {
        if (clickHandler) clickHandler();
      }}
      cursor="pointer"
      role="group"
      _hover={{
        boxShadow: boxShadowHover,
      }}
      my="2"
      // zIndex={-1}
    >
      <Box px="4" py="3">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            color={textColor}
            mt="0"
            mb="0.5"
            fontWeight="semibold"
            fontSize="lg"
            as="h2"
            lineHeight="tight"
            isTruncated
          >
            {patient.user_name}
          </Box>
          <HStack wrap="wrap" spacing="1" alignItems="center">
            {clinicDevicesData &&
              clinicDevicesData
                .filter((d) => d.clinic_user_id === patient.clinic_user_id)
                ?.map((device) => (
                  <IconifyIcon
                    key={device.device_id}
                    icon={VRHeadsetIcon}
                    width="20"
                    height="20"
                    className={`-mt-1 ${
                      textColor === 'gray.900' ? 'text-gray-900' : 'text-blue-500'
                    }`}
                  />
                ))}
          </HStack>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <Box
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="2xs"
            textTransform="uppercase"
            ml="0"
            display="flex"
          >
            <Text fontWeight="semibold">Patient ID:</Text> &nbsp;{' '}
            <Text fontWeight="normal">#{patient.clinic_user_id}</Text>
          </Box>
          <Box
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="2xs"
            textTransform="uppercase"
            ml="0"
            display="flex"
          >
            <Text fontWeight="semibold">DOB:</Text> &nbsp;{' '}
            <Text fontWeight="normal">
              <Moment format="Y-MM-DD" unix>
                {patient.dob}
              </Moment>
            </Text>
          </Box>
        </Box>

        <Box>
          <Box
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="2xs"
            textTransform="uppercase"
            ml="0"
            display="flex"
          >
            {patient.medical_record_num ? (
              <>
                <Text fontWeight="semibold">CUSTOM ID:</Text> &nbsp;{' '}
                <Text fontWeight="normal">{patient.medical_record_num}</Text>
              </>
            ) : (
              <Text fontWeight="semibold"> &nbsp; </Text>
            )}
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <Box
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="0.6rem"
            ml="0"
            mb="-1"
            display="flex"
            lineHeight="0.55rem"
          >
            <Text fontWeight="normal">Last Activity was </Text> &nbsp;{' '}
            <Text fontWeight="normal">
              {patient.last_played === '0' ? (
                'unknown'
              ) : (
                <Moment fromNow unix>
                  {patient.last_played}
                </Moment>
              )}
            </Text>
          </Box>
          <Badge
            mt="0"
            mb="-1"
            size="sm"
            fontSize="sm"
            px="2"
            borderRadius="sm"
            colorScheme={colorScheme}
            variant="outline"
            textTransform="uppercase"
            className="font-mono"
            _groupHover={{
              color: 'blue.500',
              outlineColor: 'red',
            }}
          >
            <Icon as={HomeIcon} mb="1" mr="1" ml="0" />
            {patient.home_activation_code}
          </Badge>
        </Box>
      </Box>
    </Box>
    // </Box>
  );
};
